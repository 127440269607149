<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card title="Lista de empresas">
          <div class="d-flex justify-content-between flex-wrap my-2">
            <!-- filter -->
            <b-form-group
              label="Buscador"
              label-cols-sm="3"
              label-align-sm="left"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  placeholder="Buscador"
                  type="search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Limpiar
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <b-table
            responsive
            hover
            show-empty
            bordered
            :small="true"
            :items="itemsEmpresa"
            :fields="fieldsEmpresa"
            :filter="filter"
            :filter-included-fields="filterOn"
            empty-filtered-text="No se encontro ningun resultado"
            empty-text=" "
            sticky-header="200px"
            select-mode="single"
            selectable
            @row-selected="onEmpresaSelect"
          >
            <template #empty>
              <h4 class="text-center my-2">
                No tiene información para mostrar
              </h4>
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
                <strong class="ml-1">Cargando ...</strong>
              </div>
            </template>
            <template #head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template #cell()="data">
              <span class="text-nowrap">{{ data.value }}</span>
            </template>
            <template #cell(acciones)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="idEmpresa > 0">
      <b-col
        cols="12"
        md="6"
      >
        <b-card title="Usuarios">
          <validation-observer ref="filterUsuario">
            <b-row>
              <b-col>
                <b-form-group
                  label="Archivo"
                  label-for="usuario"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="archivo usuario"
                    rules="required"
                  >
                    <b-form-file
                      id="usuario"
                      ref="refUsuario"
                      v-model="archivoUsuario"
                      placeholder="Seleccionar archivo ..."
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="d-flex align-items-center justify-content-end my-2">
                  <b-button
                    variant="primary"
                    class="ml-1"
                    @click="subirUsuario()"
                  >
                    <feather-icon
                      icon="UploadIcon"
                      class="mr-50 text-white"
                    />
                    <span class="text-white">Cargar usuarios</span>
                  </b-button>
                  <b-button
                    variant="danger"
                    class="ml-1"
                    @click="limpiarUsuario()"
                  >
                    <feather-icon
                      icon="FileIcon"
                      class="mr-50"
                    />
                    Limpiar
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-card title="Métricas">
          <validation-observer ref="filterMetrica">
            <b-row>
              <b-col>
                <b-form-group
                  label="Archivo"
                  label-for="metrica"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="archivo metrica"
                    rules="required"
                  >
                    <b-form-file
                      id="metrica"
                      ref="refMetrica"
                      v-model="archivoMetrica"
                      placeholder="Seleccionar archivo ..."
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="d-flex align-items-center justify-content-end my-2">
                  <b-button
                    variant="primary"
                    class="ml-1"
                    @click="subirMetrica()"
                  >
                    <feather-icon
                      icon="UploadIcon"
                      class="mr-50 text-white"
                    />
                    <span class="text-white">Cargar métrica</span>
                  </b-button>
                  <b-button
                    variant="danger"
                    class="ml-1"
                    @click="limpiarMetrica()"
                  >
                    <feather-icon
                      icon="FileIcon"
                      class="mr-50"
                    />
                    Limpiar
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-card title="Comisiones">
          <validation-observer ref="filterComision">
            <b-row>
              <b-col>
                <b-form-group
                  label="Archivo"
                  label-for="comision"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="archivo comision"
                    rules="required"
                  >
                    <b-form-file
                      id="comision"
                      ref="refComision"
                      v-model="archivoComision"
                      placeholder="Seleccionar archivo ..."
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="d-flex align-items-center justify-content-end my-2">
                  <b-button
                    variant="primary"
                    class="ml-1"
                    @click="subirComision()"
                  >
                    <feather-icon
                      icon="UploadIcon"
                      class="mr-50 text-white"
                    />
                    <span class="text-white">Cargar comision</span>
                  </b-button>
                  <b-button
                    variant="danger"
                    class="ml-1"
                    @click="limpiarComision()"
                  >
                    <feather-icon
                      icon="FileIcon"
                      class="mr-50"
                    />
                    Limpiar
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BFormFile,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import empresaService from '@/service/empresaService'
// eslint-disable-next-line import/no-cycle
import metricaService from '@/service/metricaService'
// eslint-disable-next-line import/no-cycle
import usuarioService from '@/service/usuarioService'
import comisionService from '@/service/comisionService'
// eslint-disable-next-line import/no-cycle

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      token: localStorage.getItem('token'),
      archivoUsuario: null,
      archivoMetrica: null,
      archivoComision: null,
      required,
      idEmpresa: 0,
      filter: null,
      filterOn: [],
      fieldsEmpresa: [
        {
          key: 'id',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          label: 'RUC',
          key: 'ruc',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Empresa',
          key: 'name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Pais',
          key: 'pais',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Contacto',
          key: 'personacontacto',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Teléfono',
          key: 'telfcontacto',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Selección',
          key: 'acciones',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      itemsEmpresa: [],
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      await this.obtenerListaEmpresa()
      this.$store.commit('app/UPDATE_ISLOADING', false)
    })
  },
  methods: {
    async obtenerListaEmpresa() {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const responseEmpresa = await empresaService.postEmpresaData(this.token, this.userData.id)
      if (responseEmpresa.data.data.length > 0) {
        this.itemsEmpresa = responseEmpresa.data.data
      }
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async onEmpresaSelect(items) {
      this.$store.commit('app/UPDATE_MESSAGE', 'Obteniendo informacion de la empresa.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      if (items.length > 0) {
        this.idEmpresa = items[0].id
      }
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async subirUsuario() {
      const success = await this.$refs.filterUsuario.validate()
      if (success) {
        try {
          this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
          this.$store.commit('app/UPDATE_ISLOADING', true)
          const data = new FormData()
          data.append('file', this.$refs.refUsuario.files[0])
          data.append('idEmpresa', this.idEmpresa)
          data.append('idUsuario', this.userData.id)
          const response = await usuarioService.postArchivoData(this.token, data)

          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              position: 'bottom-center',
              timeout: false,
              closeOnClick: true,
            },
          )
        // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
          this.limpiarUsuario()
          this.$store.commit('app/UPDATE_ISLOADING', false)
        }
      }
    },
    async subirMetrica() {
      const success = await this.$refs.filterMetrica.validate()
      if (success) {
        try {
          this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
          this.$store.commit('app/UPDATE_ISLOADING', true)
          const data = new FormData()
          data.append('file', this.$refs.refMetrica.files[0])
          data.append('idEmpresa', this.idEmpresa)
          data.append('idUsuario', this.userData.id)
          const response = await metricaService.postArchivoData(this.token, data)

          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              position: 'bottom-center',
              timeout: false,
              closeOnClick: true,
            },
          )
        // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
          this.limpiarMetrica()
          this.$store.commit('app/UPDATE_ISLOADING', false)
        }
      }
    },
    async subirComision() {
      const success = await this.$refs.filterComision.validate()
      if (success) {
        try {
          this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
          this.$store.commit('app/UPDATE_ISLOADING', true)
          const data = new FormData()
          data.append('file', this.$refs.refComision.files[0])
          data.append('idEmpresa', this.idEmpresa)
          data.append('idUsuario', this.userData.id)
          const response = await comisionService.postArchivoData(this.token, data)

          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              position: 'bottom-center',
              timeout: false,
              closeOnClick: true,
            },
          )
        // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
          this.limpiarComision()
          this.$store.commit('app/UPDATE_ISLOADING', false)
        }
      }
    },
    async limpiarUsuario() {
      this.$refs.filterUsuario.reset()
      this.archivoUsuario = null
    },
    async limpiarMetrica() {
      this.$refs.filterMetrica.reset()
      this.archivoMetrica = null
    },
    async limpiarComision() {
      this.$refs.filterComision.reset()
      this.archivoComision = null
    },
  },
}
</script>

<style>

</style>
