import axios from '@axios'
import store from '@/store'

export default {
  async postMetricaData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getMetricas`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idValue: id,
      },
    })
    return response
  },
  async postMetricaInsert(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/insertMetricas`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        name: data.nombre,
        campaignId: data.campana,
        indicatorName: data.indicador,
        targetValue: data.objetivo,
        dataType: data.tipo,
        compliance: data.cumplimiento,
        status: data.status,
        amountPoints: data.punto,
        multiplier: data.multiplicador,
        lowerThreshold: data.inferior,
        upperThreshold: data.superior,
        controlAvance: data.control,
        estado: data.estado,
      },
    })
    return response
  },
  async postMetricaUpdate(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/updateMetricas`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        name: data.nombre,
        campaignId: data.campana,
        indicatorName: data.indicador,
        targetValue: data.objetivo,
        dataType: data.tipo,
        compliance: data.cumplimiento,
        status: data.status,
        amountPoints: data.punto,
        multiplier: data.multiplicador,
        lowerThreshold: data.inferior,
        upperThreshold: data.superior,
        controlAvance: data.control,
        estado: data.estado,
        idValue: data.id,
      },
    })
    return response
  },
  async postMetricaDelete(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/deleteMetricas`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idValue: id,
      },
    })
    return response
  },
  async postArchivoData(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/process/metrics`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    return response
  },
}
