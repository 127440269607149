import axios from '@axios'
import store from '@/store'

export default {
  async postArchivoData(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/process/comisiones`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    return response
  },
}
